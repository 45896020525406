<template>
    <div class="content" style="background: white;">
      <!-- container就是要展示模型的容器 -->
      <div  id="container" class="container"></div>
      <div v-if="jindutiao" style="position: absolute;">
        <el-progress type="circle" :percentage="jindutiaonum"></el-progress>
        <div style="text-align: center;margin-top: 20px;">正在加载中</div>
      </div>
    </div>
  </template>
  <script>
  // 导入 Three.js以及相关需要用的部分
  import * as THREE from 'three';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
  import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
  import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
  import { productsInfo} from "@/api/api"; 
  export default {
    data() {
      return {
        // 初始化场景，相机，灯光，混合器等巴拉巴拉巴拉
        scene: null,
        light: null,
        camera: null,
        renderer: null,
        model: null,
        group: null,
        mixer:null,
        jindutiao:false,
        jindutiaonum:0,
        productid:'',
        url:{}
      }
    },
    created(){
        let productid  = this.$route.params.id
        this.productid = productid
    },
    // 页面渲染完毕之后，启用方法
    mounted() {
      this.init()  //初始化
      this.loadGltf()  //加载模型
    },
    methods: {
      init() {
        this.jindutiao = true
        let vm = this  //这里设置vm是this指向问题，一定要设置*******
        let container = document.getElementById("container");//获取显示3D模型的DOM结构
  
  
        // 初始化场景
        this.scene = new THREE.Scene();
        // 加载环境光，不加则什么都看不见
        //this.scene.add(new THREE.AmbientLight(0xffffff, 0.5));
        // this.light = new THREE.DirectionalLight(0xffffff, 0.5); 
        // this.light.position.set(2, 0, 1).normalize();
        // this.scene.add(this.light); //场景加载灯光
        // this.light = new THREE.DirectionalLight(0xffffff, 0.5); 
        // this.light.position.set(-5, 0, 1).normalize();
        // this.scene.add(this.light); //场景加载灯光
        // this.light = new THREE.DirectionalLight(0xffffff, 1.0); 
        // this.light.position.set(0, 12, 1).normalize();
        // this.scene.add(this.light); //场景加载灯光
        // this.light = new THREE.DirectionalLight(0xffffff, 0.05); 
        // this.light.position.set(0, -12, 1).normalize();
        // this.scene.add(this.light); //场景加载灯光
  
  
        const rgbeLoader = new RGBELoader();
        rgbeLoader.load('/models/startup.hdr',  (envMap)=> {
            this.scene.environment = envMap;
            // hdr作为环境贴图生效，设置.mapping为EquirectangularReflectionMapping
            envMap.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.background = envMap
        })
   
        // 加载网格
        // const grid = new THREE.GridHelper( 50, 10, 0x444444, 0x444444 );
        // grid.material.opacity = 0.3;  //设置网格的透明度
        // grid.material.transparent = true;  //设置网格是否可以透明
        // grid.rotation.y = Math.PI/2.0;  //设置网格方向为模型y轴方向
        // grid.position.y = -1  //设置网格位置为模型中心位置向下一格
        // grid.rotateY(-60)  //设置网格绕Y轴逆时针旋转
        // this.scene.add( grid );  //场景加载网格
        
        /**
         * 相机设置
         */
        // 初始化透视相机
        this.camera = new THREE.PerspectiveCamera(75,window.innerWidth / window.innerHeight, 0.01, 1000);
        // 设置相机位置
        this.camera.position.z = 0.2;
        this.camera.position.y = 0.01;
        this.camera.up = new THREE.Vector3(0, 1, 0);
  
        // /**
        //  * 创建渲染器对象
        //  */
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }), // 开启抗锯齿和背景透明
        this.renderer.setSize(window.innerWidth,window.innerHeight);
        this.renderer.setPixelRatio(devicePixelRatio); //干啥的不清楚，反正开启之后画质直接起飞
   
        // 相机的控件：加上它就能实现鼠标控制模型旋转、缩放
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.enableZoom = false
  
        // 最后就是把所有加载到指定的dom结构上
        container.appendChild(this.renderer.domElement);
   
      },
      loadGltf() {
        productsInfo(this.productid).then(res=>{
          let vm = this;  //也是防止this指向问题
          const loader = new GLTFLoader();  //模型加载器，有了它才能加载.glb等后缀文件并解析
          // 开始加载模型文件（可替换自己的模型文件）https://models.readyplayer.me/64f1b1fefe61576b46f28fbb.glb?cacheControl=true&uat=1693561341962
          loader.load(process.env.VUE_APP_BASE_API+res.data.info.durl, function (gltf) {
            vm.jindutiao = false
            // 加载完之后
            vm.model = gltf.scene;
            vm.model.position.set(0,-0.05,0)  //设置模型初始的位置
            vm.scene.add(vm.model)  //在场景中加载这个模型
    
            // 执行动画
            function animate() {
              // 渲染器添加场景和相机
              vm.renderer.render(vm.scene, vm.camera);
              requestAnimationFrame(animate);
              if (vm.mixer) {
                vm.mixer.update(0.016);
              }
            }
            vm.model.scale.set(1, 1, 1);
            document.addEventListener('wheel',function(event){
              console.log(event);
              if (event.deltaY < 0) {
                // 增加模型的缩放
                vm.model.scale.multiplyScalar(1.1);
                // 限制最大放大倍数为10倍
                vm.model.scale.clampScalar(0.5, 1.5);
              }
              // 如果向下滚动
              else {
                // 减小模型的缩放
                vm.model.scale.multiplyScalar(0.9);
                // 限制最小缩小倍数为1倍
                vm.model.scale.clampScalar(0.5, 1.5);
              }
            })
            animate();
          },
          function(xhr){
            let baifenbi = Math.floor(xhr.loaded/xhr.total*100)
            vm.jindutiaonum = baifenbi
          }
          );
        })
        
      }
    }
  }
  </script>
  <style  scoped>
    *{
      margin: 0;
      padding: 0;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .container {
      width: 100%;  
      height: 100vh; 
      background: white;
    }
    .container :hover {
      cursor: pointer;
    }
  </style>